// Photo part
.jumbotron {
  text-align: center;
  background-color: rgb(225, 225, 225);
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  h1 {
    font-size: 6rem;
  }
}

.services-header {
  text-align: center;
  font-size: 4rem;
  padding: 20px;
  margin: 0;
}

.modal-dialog {
  width: 55%;
  margin: auto;
  .modal-header {
    text-align: center;
    padding: 0;
    position: relative;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      span {
        font-size: 3rem;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 4rem;
    }
  }
  img {
    width: 100%;
  }
}

.services-container {
  .services-columns {
    margin-bottom: 30px;
    h3 {
      text-align: center;
    }
  }
  .services-pic {
    width: 550px;
    height: auto;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }
  .services-photo > div:first-child {
    margin-left: 25%;
  }
}

// Movie media

@media (max-width: 1199px) {
  .services-columns > h3 {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .services-movie > div:first-child {
    margin-left: 25%;
  }
}

@media (max-width: 767px) {
  .services-movie {
    .services-columns {
      width: 100%;
      iframe {
        width: 70%;
      }
    }
  }
}

@media (min-width: 992px) {
  iframe {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 991px) {
  .services-columns > h3 {
    font-size: 1.5rem;
  }
  iframe {
    max-width: 100%;
    max-height: 300px;
  }
}

@media (max-width: 674px) {
  // .services-movie {
  //   margin-left: 25%;
  // }
  .services-columns > h3 {
    font-size: 0.8rem;
  }
  iframe {
    max-width: 300px;
    height: 250px;
  }
  .modal-dialog {
    width: 80%;
  }
}
