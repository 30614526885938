.navbar-default {
  background-color: rgba(217, 217, 217, 0.7);
  border-color: transparent;
  position: fixed;
  z-index: 1;
  padding: 10px;
  width: 100%;
  transition: 5s ease-in;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: transparent;
  a {
    font-size: 1.8rem;
    color: black;
    padding: 15px;
    text-decoration: none;
    &:hover {
      font-size: 2rem;
      font-weight: bolder;
      transition: 0.5s ease;
    }
  }
}



