.gallery-container {
    border-bottom: 2px dashed black;

    h1 {
        font-size: 4rem;
    }

    button {
        margin: 20px;
        width: 200px;
        font-size: 3rem;
        border-radius: 10px;
        border: none;

        &:hover {
            background-color: rgb(51, 51, 51);
            color: white;
        }
    }

    .gallery-options {
        display: flex;
        justify-content: space-evenly;
    }

    .show-gallery {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0;

        img {
            width: 390px;
            height: 300px;
            padding: 10px;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .row {
        margin-bottom: 20px;
    }
}


@media (max-width: 540px) {
    .gallery-options {
        button {
            width: 70px;
            font-size: 1.5rem;
        }
    }
}

@media (min-width: 541px) {
    .gallery-options {
        button {
            width: 100px;
            font-size: 2.5rem;
        }
    }
}