.footer-container {
  background-color: rgba(233, 233, 233, 0.5);
  padding: 30px 30px 10px 30px;
  .footer {
    padding-bottom: 10px;
    .footer-wrapper {
      .footer-image {
        float: left;
        margin-right: 10px;
      }
      .footer-aboutMe {
        text-align: justify;
      }
    }
    .footer-social-media {
        a:hover {
          font-size: 120%;
          transition: 1s ease;
        }
    }
    .footer-copyrights {
      text-align: center;
      :first-child {
        font-weight: bold;
      }
      span {
        padding: 5px;
      }
    }
  }
}

.modal-footer > p {
  font-size: 1.5em;
}

// responsiveness of footer
@media (min-width: 1200px) {
  .footer-image {
    width: 100px;
  }
  .footer-aboutMe {
    font-size: 2rem;
    text-align: justify;
  }
  .footer-aboutMe-two {
    font-size: 2.5rem;
  }
  .footer-social-media {
    margin-top: 5%;
    .social-media-text {
      span {
        font-size: 2rem;
      }
    }
    a {
      //padding: 5px;
    }
  }
}

@media (max-width: 1199px) {
  .footer-image {
    width: 70px;
  }
  .footer-aboutMe {
    font-size: 1.3rem;
  }
  .social-media-text > span {
    font-size: 1rem;
  }

}
@media (min-width: 761px) {
  .footer-wrapper {
    width: 50%;
  }
  .footer-social-media {
    width: 30%;
    float: right;
    .social-media-text {
      padding: 5px;
    }
    a {
      padding: 5px;
    }
  }
}
@media (max-width: 760px) {
  .footer-wrapper {
    width: 100%;
  }
  .footer-social-media {
    width: 100%;
    display: flex;
    .social-media-text {
      padding: 2px;
      a {
        display: block;
        text-align: center;
      }
    }
    .social-media-icon {
      padding: 5px;
    }
  }
  .footer-social-media > div:nth-child(4) > img {
    margin-left: 25%;
  }
  .footer-copyrights > span {
    font-size: 1rem;
  }
}


@media (max-width: 612px) {
  .footer-copyrights > span {
    font-size: 0.8rem;
    display: block;
    padding: 0;
  }
}
