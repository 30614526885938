$transitionOfHeader: ease-in 0.5s;

.parallax {
  height: 500px;
  .pricelistContainer {
    // width: 600px;
    margin: auto;
    display: flex;
    justify-content: center;
    .pricelistPhoto, .pricelistVideo {
      padding: 10px 6px 0 6px;
      // padding-top: 20px;
      // padding-left: 20px;
      width: 40%;
      text-align: center;
      margin: 5px;
      background-color: rgba(248, 248, 248, 0.5);
      h3 {
      font-weight: bold;
      padding: 2px;
      margin: 0;
      }
    }
  }
  h2 {
    height: 50%;
    text-align: right;
    // font-size: 3.5rem;
    color: black;
  }
  // .parallax-wrapper {
  //   // margin-right: 50px;
  //   text-align: right;
  //   position: absolute;
  //   top: 0px;
  //   right: 30px;
  //   .gallery-btn {
  //     background-color: transparent;
  //     border-color: transparent;
  //     img {
  //       text-decoration: none;
  //       font-size: 3rem;
  //       border-radius: 10px;
  //       transform: scale(1);
  //       transition: 1s;
  //       &:hover {
  //         transform: scale(1.5);
  //         color: black;
  //         font-weight: bolder;
  //         font-size: 4rem;
  //         border-radius: 10px;
  //       }
  //     }
  //   }
  // }
}

@media (min-width: 950px) {
  .pricelistContainer {
    width: 600px;
    transition: $transitionOfHeader;

      h3 {
      font-weight: bold;
      }
  }
  .parallax-wrapper> h2 {
    font-size: 3.5rem;
    transition: $transitionOfHeader;

  }
}
@media (max-width: 949px) {
  .pricelistContainer {
    width: 250px;
    transition: $transitionOfHeader;

      h3 {
      font-weight: bold;
      font-size: 1.5rem;
      }
  }
  .parallax-wrapper> h2 {
    font-size: 2.5rem;
    transition: $transitionOfHeader;
  }
}
@media (max-width: 570px) {
  .pricelistContainer {
    width: 180px;
    transition: $transitionOfHeader;

      h3 {
      font-weight: bold;
      font-size: 0.9rem;
      }
  }
  .parallax-wrapper> h2 {
    font-size: 1.6rem;
    transition: $transitionOfHeader;

  }
}