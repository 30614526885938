body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Condensed', Arial, sans-serif;
  background-color: #ececec;
}



// Menu

@import "partials/menu.scss";

// Background
@import "partials/header.scss";

// Services
@import "partials/services.scss";
@import "partials/services.scss";

// Parallax
@import "partials/parallax.scss";


// Gallery
@import "partials/gallery.scss";


// Footer
@import "partials/footer.scss";


// Modals
@import "partials/centerModals.scss";

