//Header Movie

.header-movie-wrapper {
  overflow: hidden;
  display: flex;
  margin: auto;
  .header-movie {
    width: 100%;
    height: 100vh;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-title {
      z-index: 1;
      .header-title-header {
        color: white;
        letter-spacing: 10px;
        font-size: 10rem;
        text-align: center;
        cursor: default;
      }
      .header-title-paragraph {
        color: white;
        font-size: 5rem;
        letter-spacing: 5px;
        cursor: default;
        text-align: center;
      }
      .header-title-btn {
        width: 200px;
        height: 50px;
        text-align: center;
        background-color: transparent;
        font-size: 3rem;
        margin-left: 25%;
        cursor: pointer;
        z-index: 5;
        border-top: 2px solid white;
        border-left: 2px solid white;
        border-right: 2px solid grey;
        border-bottom: 2px solid grey;
        a {
          color: white;
          text-decoration: none;
        }
        &:hover {
          background-color: rgba(255,255,255,0.1);
        }
      }
    }
  }
}
